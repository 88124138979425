<template>
  <div>
    <div style="margin-top: 10px; margin-bottom: 0" class="divider">
      <span style="color: #666; padding-left: 5px"
        ><v-icon style="color: #666 !important">pie_chart</v-icon> Grade Wise
        Students
      </span>
      <span class="dashboard_refresh_button">
        <v-icon medium @click="fillGradesChart(true)" v-if="!refresh_status"
          >autorenew</v-icon
        >
        <v-progress-circular
          indeterminate
          color="#ccc"
          v-else
          :size="20"
        ></v-progress-circular>
      </span>
    </div>
    <apexchart
      type="pie"
      width="350"
      :options="studentChart.options"
      :series="studentChart.data"
    ></apexchart>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      studentChart: {
        options: {
          legend: {
            position: "bottom",
          },
          labels: [],
        },
        data: [],
      },
      refresh_status: false,
    };
  },
  watch:{
     "batch.id": {
       handler: function(val) {
           this.fillGradesChart(); // call it in the context of your component object
       },
       deep: true
    }
  },
  computed: {
    ...mapState(["batch"]),
  },
   mounted() {
  },
  methods: {
    fillGradesChartOld(params = false) {
      if (localStorage.getItem("dashboard") === null || params) {
        this.$rest
          .get("/api/grades?rowsPerPage=50&descending=false")
          .then(({ data }) => {
            this.refresh_status = true;
            let $this = this;
            $this.studentChart.options.labels = [];
            $this.studentChart.data = [];
            data.data.map(function(item) {
              $this.studentChart.options.labels.push(item.name);
              $this.studentChart.data.push(item.student_count);
            });
            const grade_pie = data.data;
            let old_value = JSON.parse(
              window.localStorage.getItem("dashboard")
            );
            window.localStorage.removeItem("dashboard");
            let grade_pie_count = {
              grade_pie,
              ...old_value,
            };
            window.localStorage.setItem(
              "dashboard",
              JSON.stringify(grade_pie_count)
            );
            setTimeout(() => {
              this.refresh_status = false;
            }, 2000);
          });
      } else {
        const grade_pie = JSON.parse(window.localStorage.getItem("dashboard"));
        const data = grade_pie.grade_pie;
        let $this = this;
        data.map(function(item) {
          $this.studentChart.options.labels.push(item.name);
          $this.studentChart.data.push(item.student_count);
        });
      }
    },
    fillGradesChart(params = false) {
      let check_grade_list = "";
      if (window.localStorage.getItem("grade_list") !== null) {
        check_grade_list = JSON.parse(
          window.localStorage.getItem("grade_list")
        );
      }
      if (
        check_grade_list[this.batch.id] === null ||
        check_grade_list[this.batch.id] === undefined ||
        check_grade_list == ""
      ) {
        let grade_list = JSON.parse(window.localStorage.getItem("grade_list"));
        this.$rest
          .get("/api/grades?rowsPerPage=50&descending=false")
          .then(({ data }) => {
            let $this = this;
            $this.studentChart.options.labels = [];
            $this.studentChart.data = [];
            data.data.map(function(item) {
              $this.studentChart.options.labels.push(item.name);
              $this.studentChart.data.push(item.student_count);
            });
            let batch_id = this.batch.id;
              grade_list = {
                ...grade_list,
                [batch_id]: data.data,
              };
            window.localStorage.setItem(
              "grade_list",
              JSON.stringify(grade_list)
            );
          })
          .catch((e) => {});
      } else {
        const grade_list = JSON.parse(
          window.localStorage.getItem("grade_list")
        );
        const data = grade_list[this.batch.id];
        let $this = this;
        data.map(function(item) {
          $this.studentChart.options.labels.push(item.name);
          $this.studentChart.data.push(item.student_count);
        });
      }
    },
  },
};
</script>
